import './App.css';
import FreightSvg from "./assets/freight.png"
import FulfillmentSvg from "./assets/fulfillment.png"

function App() {
  const handleNavigateToSite = (isFulfillment) => {
    if (isFulfillment) {
      return process.env.REACT_APP_FULFILLMENT_URL;
    } else {
      return process.env.REACT_APP_FREIGHT_URL;
    }
  }

  return (
    <div className="App">
      <div>
        <a href={handleNavigateToSite(false)}>
          <img className="image" src={FreightSvg} alt='Freight'/>
          <p>PicknPack Freight</p>
        </a>
      </div>
      <div>
        <a href={handleNavigateToSite(true)}>
          <img className="image" src={FulfillmentSvg} alt='Fulfillment'/>
          <p>PicknPack Fulfillment</p>
        </a>
      </div>
    </div>
  );
}

export default App;
